<template>
  <div
    id="google-continue"
    class="g_id_signin"
    dir="auto"
  ></div>
</template>

<script>
import axios from '@axios'

// import abilityObj from '@/plugins/acl/ability'
import { i18n } from '@/plugins/i18n/index'
import { authenticate } from '@/views/globalHelpers'
import { watch } from '@vue/composition-api'

export default {
  setup() {
    return {}
  },
  data() {
    return {
      googleLoaded: false,
    }
  },
  mounted() {
    const renderGoogleButton = () => {
      window.google.accounts.id.renderButton(document.getElementById('google-continue'), {
        theme: 'outline',
        size: 'large',
        locale: i18n.locale,
        text: 'sign_in_with',
        type: 'standard',
        logo_alignment: 'left',
        shape: 'pill',
      })
    }
    const initializeGsi = () => {
      if (!window.google || this.googleLoaded) return

      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.handleGoogleSignIn,
      })

      window.google.accounts.id.prompt()
      renderGoogleButton()

      this.googleLoaded = true
    }
    watch(
      () => i18n.locale,
      () => {
        renderGoogleButton()
      },
    )

    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = initializeGsi
    script.async = true
    script.id = 'google-client-script'
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('body')?.appendChild(script)
  },
  methods: {
    handleGoogleSignIn(user) {
      authenticate(
        axios.post('google-authentication', {
          token: user.credential,
        }),
        () => {},
      )
    },
  },
}
</script>
<style>
</style>
